.statistic {
  width: 100%;
  display: flex;
  padding: 0.4rem;
  box-sizing: border-box;
  background: var(--settings-background);
  border-radius: 10px;
  font-weight: bold;
  font-size: .9rem;
  height: 2rem;
  overflow: hidden;
  transition: all .5s ease-out;
  position: relative;
  &_isOpen {
    height: 10rem;
  }
  &__coefs-container {
    width: calc(100% - .6rem);
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    padding-right: 1rem;
    padding-bottom: .4rem;
    &_isOpen {
      height: 10rem;
    }
    &::-webkit-scrollbar {
      margin-top: 3px;
      width: 2px;
      height: 0;
      opacity: 0;
    }
    &::-webkit-scrollbar-track {
      border-radius: 50px;
      margin-top: 1.7rem;
      margin-bottom: 1.7rem;
      background: var(--main-background);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--accent-color);
      border-radius: 50px;
    }
  }
  &__coef {
    width: 18%;
    min-width: 18%;
    height: 1.2rem;
    border-radius: 20px;
    margin-bottom: .4rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: increaseCoef .5s linear forwards;
    &:not(:nth-child(5n)) {
      margin-right: 2.5%;
    }
  }
  &__arrow {
    width: 1.2rem;
    min-width: 1.2rem;
    height: 1.2rem;
    transition: all .5s ease-out;
    position: absolute;
    cursor: pointer;
    right: .5rem;
    &_isOpen {
      transform: rotate(180deg);
    }
  }
}

@media screen and (min-width: 700px) {
  .statistic {
    position: absolute;
    z-index: 100;
    height: 100%;
    background: var(--main-background);
    // background: #000;
    padding: 0.5rem .6rem;

    &__coefs-container {
      &_isOpen {
        height: 9rem;
      }
    }
    &_isOpen {
      height: 9rem;
    }
    &__coef {
      width: 10.5%;
      height: 1.25rem;
      margin-bottom: .6rem;
    }
  }
}

@keyframes increace {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 100vh;
  }
}

@keyframes reduce {
  0% {
    display: flex;
    max-height: 100vh;
  }
  99% {
    display: flex;
  }
  100% {
    display: none;
    max-height: 0;
  }
}

@keyframes increaseCoef {
  0% {
    max-width: 0;
  }
  100% {
    max-width: 10.5%;
  }
}
