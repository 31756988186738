.advice {
  position: absolute;
  width: 10rem;
  border: 1px solid var(--accent-color);
  background: rgba(255, 141, 0, 1);
  border-radius: 7px;
  white-space: normal;
  z-index: 105;
  font-size: .9rem;
  box-sizing: border-box;
  padding: .5rem;
  opacity: 0;
  display: none;
  &_isVisible {
    display: block;
    animation: adviceAnimation 5s linear forwards;
  }
}

@keyframes adviceAnimation{
  0% {
    display: block;
    opacity: 0;
  }
  10% {
    display: block;
    opacity: 1;
  }
  90% {
    display: block;
    opacity: 1;
  }
  100% {
    display: block;
    opacity: 0;
  }
}