.settings {
  position: absolute;
  right: 0;
  top: 0;
  max-height: 2.5rem;
  min-width: 2.5rem;
  max-width: 2.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 1.25rem;
  background: var(--settings-background);
  box-sizing: border-box;
  transition: max-width 1s, max-height 1s;
  z-index: 1004;
  &_isOpen {
    width: initial;
    height: initial;
    max-width: 13.3rem;
    max-height: 13.3rem;
    padding-bottom: 0.65rem;
  }
  &__icon {
    margin-left: auto;
    width: 2.5rem;
    max-height: 2.5rem;
    padding: 0.65rem;
    box-sizing: border-box;
    display: block;
    line-height: 0;
    position: relative;
    cursor: pointer;

    &-svg {
      max-height: 1.2rem;
      * {
        fill: var(--text);
      }
    }
    &_isOpen {
      &-svg {
        max-height: 1.2rem;
        * {
          fill: var(--accent-color);
        }
      }
    }
  }
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: .65rem;
    margin-left: .65rem;
    margin-top: 1rem;

    &:nth-child(2) {
      margin-top: .35rem;
    }
  }
  &__text {
    margin-right: 2rem;
  }
}