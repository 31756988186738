.rules {
  width: 100%;
  height: 100%;
  background: var(--main-background);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 1rem;
  overflow-y: scroll;
  position: relative;

  &__header {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  &__button-container  {
    position: absolute;
    max-width: 50%;
    height: 2.5rem;
    top: 1rem;
    right: 1rem;
  }
  
  &__text {
    overflow: hidden;
  }
}

@media screen and (min-width: 700px) {
  .rules {
    padding: 1rem 15%;
    &__header {
      margin-bottom: 0.5rem;
    }
    &__button-container {
      right: 15%;
      top: 0.75rem;
    }
  }
}