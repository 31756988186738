@import './themeVariables.scss';

$themes: (
    orange: $orange-theme,
    purple: $purple-theme,
);

@each $theme, $map in $themes {
    .theme-#{$theme} {
        --main-background: #{map-get($map, 'main-background')};
        --text: #{map-get($map, 'text')};
        --accent-color: #{map-get($map, 'accent-color')};
        --component-background: #{map-get($map, 'component-background')};
        --settings-background: #{map-get($map, 'settings-background')};
        --id-color: #{map-get($map, 'id-color')};
        --chat-text: #{map-get($map, 'chat-text')};
        --statistic-color1: #{map-get($map, 'statistic-color1')};
        --statistic-color2: #{map-get($map, 'statistic-color2')};
        --statistic-color3: #{map-get($map, 'statistic-color3')};
        --statistic-color4: #{map-get($map, 'statistic-color4')};
    }
}