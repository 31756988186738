.basket {
  border-radius: 10px;
  width: 100%;
  font-weight: normal;
  display: flex;
  user-select: none;
  overflow: hidden;

  &:nth-child(2) {
    margin-top: .5rem;
  }
  &__bets-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-transform: uppercase;
    box-sizing: border-box;
    padding: 0.8rem;
    background: var(--main-background);
  }
  &__text {
    font-weight: bold;
    font-size: .8rem;
    white-space: nowrap;
  }
  &__more-basket {
    background: var(--component-background);
    width: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  &__more-plus {
    width: 1.3rem;
    * {
      fill: var(--accent-color);
    }
  }
  &__left,
  &__right {
    width: calc(50% - .4rem);
  }
  &__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.1rem;
    &:last-child {
      margin-top: .5rem;
    }
  }
  &__bets-block {
    width: 49%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__bet-block {
    width: 32%;
    height: 2.1rem;
    background: var(--component-background);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in;
  }
  &__change-container {
    width: 100%;
    height: 100%;
    display: flex;
    background: var(--component-background);
    border-radius: 7px;
    &_isBlock {
      opacity: .5;
    }
  }
  &__change-block {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:nth-child(2) {
      width: 50%;
    }
    &_isMinimalAmount {
      opacity: .4;
    }
  }
  &__change-icon {
    display: block;
    height: 1.4rem;
    width: 1.4rem;
    * {
      fill: var(--text);
    }
  }
  &__button-container {
    width: 100%;
    height: 3.5rem;
    margin-top: 0.6rem;
  }
}

.basket-desktop {
  border-radius: 10px;
  background: var(--main-background);
  width: 100%;
  height: 100%;
  font-weight: normal;
  display: flex;
  user-select: none;
  overflow: hidden;
  box-sizing: border-box;
  padding: 1rem;
  &_isDouble {
    width: 50%;
    flex-direction: column;
    border-radius: 10px 0 0 10px;
    padding: 1rem;
    &.basket-desktop_lang {
      &_fa {
        font-size: .75rem;
      }
      &_it {
        font-size: .85rem;
      }
    }
    &:first-child {
      padding-right: 0.5rem;
    }
    &:last-child {
      padding-left: 0.5rem;
    }
    &:nth-child(2) {
      border-radius: 0 10px 10px 0;
    }
    .basket-desktop {
      &__change-container {
        width: 59%;
      }
      &__more-basket {
        width: 9%;
        height: 100%;
        padding: 0 1rem;
        margin-left: 1rem;       
      }
      &__more-plus {
        width: 1.6rem;
        margin-top: 0;
        line-height: 0;
      }
      &__switch-container {
        width: 24%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        margin-left: .5rem;
      }
      &__bet-block {
        width: calc(25% - .75rem);
        text-align: center;
      }
      &__row {
        padding: .5rem 0;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
      }
      &__text {
        white-space: nowrap;
      }
    }
  }
  &_isBlock {
    .basket-desktop__row_alwaysActive {
      > * {
        position: relative;
      }
    }
    .basket-desktop__row:not(.basket-desktop__row_alwaysActive) {
      * {
        cursor: not-allowed !important;
      }
      .button {
        cursor: pointer !important;
      }
    }
  }
  &__bets-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-transform: uppercase;
    box-sizing: border-box;
    background: var(--main-background);
  }
  &__more-basket {
    border: 2px solid var(--accent-color);
    cursor: pointer;
    padding: 0 2rem;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    z-index: 100;
  }
  &__more-text {
    text-orientation: upright;
    writing-mode: vertical-rl;
  }
  &__more-plus {
    width: 2.5rem;
    margin-top: 10px;

    * {
      fill: var(--accent-color);
    }
  }
  &__left,
  &__right {
    width: calc(50% - .4rem);
    margin-right: 3rem;
    height: 100%;
  }
  &__change-icon {
    display: block;
    height: 1.4rem;
    width: 1.4rem;
    * {
      fill: var(--text);
    }
  }
  &__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25%;
    box-sizing: border-box;
    padding: .25rem 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    &_size {
      &_big {
        height: 50%;
      }
    }
  }
  &__change-container {
    width: 100%;
    height: 100%;
    display: flex;
    background: var(--component-background);
    border-radius: 7px;
    &_isBlock {
      opacity: .5;
      * {
        cursor: not-allowed;
      }
    }
  }
  &__change-block {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    &:nth-child(2) {
      width: 50%;
    }
    &_isMinimalAmount {
      opacity: .4;
      cursor: not-allowed;
    }
  }
  &__bet-block {
    width: 28%;
    height: 100%;
    background: var(--component-background);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in;
    cursor: pointer;
    text-align: center;
    &_size {
      &_big {
        width: calc(50% - .25rem);
      }
    }
  }
}