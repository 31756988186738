.switch-box {
  width: 2.4rem;
  min-width: 2.4rem;
  height: 1.3rem;
  background: var(--component-background);
  border-radius: 50px;
  display: flex;
  cursor: pointer;
  &__indicator {
    height: 1.3rem;
    width: 1.3rem;
    border-radius: 100%;
    background: var(--text);
    margin-left: 0;
    transition: all .3s ease-out;
    &_active {
      margin-left: 1.1rem;
      background: var(--accent-color);
    }
  }
}