.top-wins {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  transition: max-height 0.8s linear;
  overflow: hidden;
  &__items {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  &_isOpen {
    max-height: 50rem;
    .main-bets-list__item:nth-child(n+3) {
      max-height: 20rem;
      overflow: hidden;
      margin-bottom: 0.7rem;
    }
  }
  &__arrow {
    width: 1.4rem;
    transition: all 0.5s linear;
    &_isRotate {
      transform: rotate(180deg);
    }
  }
  &__item {
    width: 100%;
    padding: 1rem;
    background: var(--component-background);
    margin-bottom: 0.7rem;
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }
  &__column {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    &_type {
      &_names {
        align-items: flex-start;
        justify-content: space-around;
      }
      &_values {
        align-items: flex-end;
        justify-content: space-around;
        position: relative;
      }
    }
  }
  &__row {
    white-space: nowrap;
    &:not(:last-child) {
      margin-bottom: .5rem;
    }
  }
  &__guard {
    width: 1rem;
    position: absolute;
    bottom: 0;
  }
  &__icon {
    width: 80%;
  }
}

@media screen and (min-width: 700px) {
  .top-wins {
    max-height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      margin-top: 3px;
      width: 2px;
      height: 0;
      opacity: 0;
    }
    &::-webkit-scrollbar-track {
      border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--accent-color);
      border-radius: 50px;
    }
    &__arrow {
      display: none;
    }
    &__item {
      &:nth-child(n+3) {
        max-height: 20rem;
        overflow: hidden;
        margin-bottom: 0.7rem;
      }
      width: 100%;
    }
  }
}