#notify {
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  position: fixed;
  right: 1rem;
  margin: auto;
  overflow: hidden;
  width: 70%;
  z-index: 10000;
}

@media screen and (min-width: 700px) {
  #notify {
    width: 15%;
  }
}