.edit-user {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: calc(100% * 0.3);
  width: 100%;
  border-radius: 0 0 10px 10px;
  background: var(--main-background);
  z-index: 101;
  top: 4.2rem;
  overflow: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 1rem;
  max-height: calc(100% * 0.3);
  &__icons-block {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &__icon {
    width: calc(100% / 6);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    box-sizing: border-box;
    padding: .3rem;
    background: transparent;
    transition: all .5s linear;

    &_active {
      background: var(--accent-color);
    }

    &-svg {
      width: 100%;
    }
  }

  &__top-block {
    display: flex;
    width: 100%;
    margin-bottom: 0.5rem;
    align-items: center;
  }

  &__item {
    width: 2.5rem;
    height: 2.5rem;
    max-height: 2.5rem;
    min-width: 2.5rem;
    border-radius: 100%;
    margin-left: 0.6rem;
    background: var(--accent-color);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:last-child {
      background: var(--settings-background);
    }
  }

  &__save,
  &__close {
    width: 1.1rem;
    max-height: 1.1rem;
    display: block;
    line-height: 0;
    &-svg {
      max-height: 1.1rem;
      * {
        fill: var(--text);
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .edit-user {
    height: 16rem;
    top: 4rem;
    background: var(--component-background);
    border-color: var(--main-background);
    border-style: solid;
    border-radius: 10px;
    padding: 1rem;
    border-width: 10px;
    max-height: 16rem;
    &__icon {
      width: calc(100% / 5);
    }
  }
}

@keyframes increaceEdit {
  0% {
    max-height: 0;
    padding: 0 1rem;
  }
  100% {
    padding: 1rem;
    max-height: calc(100% * 0.3);
  }
}

@keyframes reduceEdit {
  0% {
    padding: 1rem;
    max-height: calc(100% * 0.3);
  }
  100% {
    max-height: 0;
    padding: 0 1rem;
  }
}

@keyframes increaceEditDesk {
  0% {
    max-height: 0;
    border-width: 0;
    padding: 0 1rem;
  }
  100% {
    padding: 1rem;
    border-width: 10px;
    max-height: 16rem;
  }
}

@keyframes reduceEditDesk {
  0% {
    padding: 1rem;
    border-width: 10px;
    max-height: 16rem;
  }
  100% {
    max-height: 0;
    border-width: 0;
    padding: 0 1rem;
  }
}