.firework {
  height: 8rem;
  margin-right: 0rem;
  margin-top: -6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &_isHorizon {
    .coef {
      top: -0.8rem;
    }
  }
  &_isEqualMarginAndHeight {
    margin-top: -8rem;
    .coef {
      top: -2rem;
    }
  }
  &_type {
    &_2 {
      margin-top: -8rem;
      animation: hideAndVisible 2s ease-in-out forwards;
      &.firework_notNeedAfterAnimation {
        animation: none;
      }
      &.firework_isCoefAfterToCenter {
        .coef {
          top: initial;
          right: initial;
          z-index: 1002;
          font-size: 2rem;
        }
      }
    }
  }
  &__animation-container {
    background: url('../img/line.png');
    background-size: 100% 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    &_isAnimation {
      animation: animationContainerStart 5s ease-in forwards, animationContainer 3s linear 5s infinite;

      &.firework__animation-container_isHorizon {
        animation: animationContainerHorizonStart 5s ease-in forwards, animationContainerHorizon 3s linear 5s infinite;
      }
    }
    &_isHorizon {
      background: none;
    }
    &_isPauseAnimation {
      animation-play-state: paused !important;
    }
  }
  &__rocket {
    height: 100%;
    transform-origin: 1.25rem calc(100% - 2rem);
    position: absolute;
    transform: rotate(0);
    &_type {
      &_1 {
        animation: rockeResizeAnimation 1s alternate-reverse infinite linear;
      }
      &_2 {
        transform-origin: 100% 50%;
      }
    }
    &_notNeedRotate {
      animation: rockeResizeWithoutRotateAnimation 1s alternate-reverse infinite linear;
    }
    &_isSmall {
      height: 75%;
    }
    &_isBig {
      height: 175%;
      bottom: 0;
    }
    &_isPauseAnimation {
      animation-play-state: paused !important;
    }
  }
  &__cashed-item {
    background: var(--component-background);
    font-size: .8rem;
    font-weight: normal;
    box-sizing: border-box;
    padding: .3rem .5rem;
    position: absolute;
    white-space: nowrap;
    animation: cashedCrash 4s linear forwards;
    &_isHorizon {
      animation: cashedCrashHorizon 4s linear forwards;
    }
    &_isMine {
      border: 1px solid var(--accent-color);
    }
  }
  &__cashed-container {
    margin-right: 0rem;
    margin-top: 0rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 700px) {
  .firework {
    height: 10rem;
    margin-right: 0rem;
    margin-top: -8rem;
    &_isEqualMarginAndHeight {
      margin-top: -10rem;
      .coef {
        top: -2rem;
      }
    }
    &_type {
      &_2 {
        margin-top: -10rem;
      }
    }
    &__animation-container {
      &_type {
        &_2 {
          width: calc(50% - 10rem);
          height: calc(50% - 9rem);
        }
      }
    }
    &_notNeedCoefTop {
      .coef {
        top: 0;
      }
    }
  }
}

@keyframes animationContainer {
  0% {
    width: calc(100% - 6rem);
    @media screen and (min-width: 700px) {
      width: calc(100% - 10rem);
    }
  }
  25% {
    width: calc(100% - 5rem);
    @media screen and (min-width: 700px) {
      width: calc(100% - 8rem);
    }
  }
  75% {
    width: calc(100% - 7rem);
    @media screen and (min-width: 700px) {
      width: calc(100% - 12rem);
    }
  }
  100% {
    width: calc(100% - 6rem);
    @media screen and (min-width: 700px) {
      width: calc(100% - 10rem);
    }
  }
}

@keyframes rockeResizeAnimation {
  0% {
    transform: rotate(30deg) scale(1.1);
  }
  100% {
    transform: rotate(30deg) scale(0.9);
  }
}

@keyframes rockeResizeWithoutRotateAnimation {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
  }
}

@keyframes hideAndVisible {
  0% {
    transform: rotate(0) scale(0.5);
  }
  25% {
    transform: rotate(0) scale(1.15);
  }
  50% {
    transform: rotate(0) scale(0.9);
  }
  100% {
    transform: rotate(0) scale(1);
  }
}

@keyframes cashedCrash {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-300px, 300px);
  }
}

@keyframes cashedCrashHorizon {
  0% {
    bottom: 0;
    left: 0;
    // transform: translate(0, 0);
  }
  100% {
    bottom: 450px;
    left: -300px;
    // transform: translate(-300px, -450px);
  }
}


@keyframes animationContainerStart {
  0% {
    width: 3rem;
    height: 3rem;
  }
  100% {
    width: calc(100% - 6rem);
    height: calc(100% - 8rem);
    @media screen and (min-width: 700px) {
      width: calc(100% - 10rem);
      height: calc(100% - 9rem);
    }
  }
}

@keyframes animationContainerHorizonStart {
  0% {
    width: 3rem;
    height: 1rem;
  }
  100% {
    width: calc(100% - 9rem);
    height: 1rem;
    @media screen and (min-width: 700px) {
      width: calc(100% - 10rem);
      height: 1rem;
    }
  }
}

@keyframes animationContainerHorizon {
  0% {
    width: calc(100% - 9rem);
    @media screen and (min-width: 700px) {
      width: calc(100% - 10rem);
    }
  }
  25% {
    width: calc(100% - 8rem);
    @media screen and (min-width: 700px) {
      width: calc(100% - 8rem);
    }
  }
  75% {
    width: calc(100% - 10rem);
    @media screen and (min-width: 700px) {
      width: calc(100% - 12rem);
    }
  }
  100% {
    width: calc(100% - 9rem);
    @media screen and (min-width: 700px) {
      width: calc(100% - 10rem);
    }
  }
}
