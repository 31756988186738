.locale {
  background: var(--text);
  &__title {
    background: linear-gradient(to bottom,#0e70b9 0,#005693 100%);
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: var(--text);
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: 400;
  }
  &__item {
    width: 100%;
    height: 2.8rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 0.7rem;
    border-bottom: 1px solid #bbb;
    &_active {
      background: #b0bfd6;
    }
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    box-sizing: border-box;
    padding: .7em 1em;
  }
  &__button {
    width: 100%;
    height: 2.8rem;
    background: #079c20;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--text);
    cursor: pointer;
  }
  &__ok {
    width: 1.8rem;
    position: absolute;
    right: 1rem;
  }
}