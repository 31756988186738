.keyboard {
  width: 100vw;
  box-sizing: border-box;
  padding: 1rem 0.5rem;
  background: var(--settings-background);
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: open 0.5s ease-in-out forwards;
  z-index: 101;
  &_isClosing {
    animation: close 0.5s ease-in-out forwards;
  }
  &__amount {
    width: 50%;
    margin-bottom: 0.5rem;
    border-bottom: var(--accent-color) 2px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    height: 1.5rem;
    box-sizing: border-box;
    position: relative;
  }
  &__close-svg {
    width: 1rem;
    height: 1rem;
    line-height: 0;
  }
  &__close {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: calc(150% - 2rem);
    border: 1px solid var(--accent-color);
    * {
      fill: var(--accent-color);
    }
  }
  &__buttons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__button {
    width: 19%;
    margin-bottom: 0.4rem;
    height: 2.5rem;
    background: var(--component-background);
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__bottom-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__bottom-button {
    width: 32%;
    height: 2.5rem;
    background: var(--component-background);
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__submit-container {
    width: 32%;
    height: 2.5rem;
  }
  &__backspace {
    width: 25%;
    * {
      fill: var(--accent-color);
    }
  }
}

@keyframes open {
  0% {
    bottom: -12.3rem;
  }
  100% {
    bottom: 0;
  }
}

@keyframes close {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -12.3rem;
  }
}