.modal {
  max-width: 40rem;
  max-height: 80vh;
  position: relative;
  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100000;
    background: rgba(0, 0, 0, .8);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__cross {
    width: 2.5rem;
    height: 2.5rem;
    max-height: 2.5rem;
    min-width: 2.5rem;
    border-radius: 100%;
    margin-left: 0.6rem;
    background: var(--accent-color);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: -0;
    top: -0;
  }
  &__close {
    width: 1.1rem;
    max-height: 1.1rem;
    display: block;
    line-height: 0;
    &-svg {
      max-height: 1.1rem;
      * {
        fill: var(--text);
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .modal {
    max-width: 90vw;
    max-height: 80vh;
    &__wrapper {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100000;
      background: rgba(0, 0, 0, .8);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}