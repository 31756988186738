.subheader {
  background: var(--main-background);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;

  &__guard {
    width: 2.5rem;
    height: 2.5rem;
    max-height: 2.5rem;
    min-width: 2.5rem;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__guard-icon {
    width: 2.5rem;
    max-height: 2.5rem;
    display: block;
    line-height: 0;
    &-svg {
      max-height: 2rem;
      * {
        fill: var(--accent-color);
      }
    }
  }

  &__item {
    width: 2.5rem;
    height: 2.5rem;
    max-height: 2.5rem;
    min-width: 2.5rem;
    border-radius: 100%;
    margin-right: 0.6rem;
    background: var(--settings-background);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &_isNewYear {
      background: none;
    }
  }
  &__icon {
    width: 1.1rem;
    max-height: 1.1rem;
    display: block;
    line-height: 0;
    position: relative;
    &-svg {
      max-height: 1.1rem;
      * {
        fill: var(--text);
      }
    }
  }
}