.jackpot-item {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  position: relative;
  &__name {
    width: 35%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--text);
    box-sizing: border-box;
    box-shadow: 0px 0px 4px var(--text);
    text-transform: uppercase;
    font-style: italic;
    font-weight: 700;
    font-size: 1rem;
    letter-spacing: 0.1em;
    &:first-child {
      padding-right: 5%;
      border-radius: 10px 0 0 10px;
    }
    &:last-child {
      padding-left: 5%;
      margin-left: auto;
      border-radius: 0 10px 10px 0;
    }
  }
  &__amount {
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--text);
    box-sizing: border-box;
    box-shadow: 0px 0px 4px var(--text);
    border-radius: 10px;
    position: absolute;
    z-index: 1;
    background: var(--settings-background);
    font-weight: 900;
  }
  &__currency {
    font-style: italic;
    margin-left: .2rem;
    color: var(--accent-color);
  }
  &__winner {
    font-size: .6rem;
    line-height: .8rem;
    white-space: nowrap;
    position: absolute;
    z-index: 2;
    background: var(--settings-background);
    top: 100%;
  }
  .odometer {
    color: var(--accent-color);
    font-family: Roboto;
    font-style: normal;
    font-style: italic;
    letter-spacing: 0.2em;
    white-space: nowrap;
  }
}
@media screen and (min-width: 700px) {
  .jackpot-item {
    &__name {
      font-size: .9rem;
    }
    &__currency {
      font-size: .9rem;
    }
    .odometer {
      font-size: .8rem;
    }
  }
}