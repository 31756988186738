.bets {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    padding: 0.2rem 0.5rem 1rem 0.5rem;
  }
  &__items {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  &__item {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 1rem 0.5rem;
    background: var(--component-background);
    border-radius: 10px;
    overflow: hidden;
    transition: background .5s ease-in;
    &:not(:last-child) {
      margin-bottom: 0.3rem;
    }
    &:first-child {
      animation: increaceResult 0.5s linear forwards;
    }
    &_isWin {
      background: var(--accent-color);
    }
    &_isMine {
      border: 1px solid var(--text);
    }
  }
  &__column {
    width: 25%;
    max-width: 25%;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

@keyframes increaceResult {
  0% {
    max-height: 0;
    padding: 0 0.5rem;
  }
  100% {
    padding: 1rem 0.5rem;
    max-height: 4rem;
  }
}