.mega_wins {
    display: block;
    position: absolute;
    z-index: 10000;
    top: 0px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-color:  rgba(44, 48, 63, 0.74);
    background-image: url('./img/background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    animation: pulse 0.5s ease-in-out;
    left: 0;
    &_isClosed {
      animation: closing 0.25s linear;
    }
    @media screen and (max-width: 700px) {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    &__image {
      > svg {
        width: 100%;
        height: 100%;
        animation: moveImg 11s infinite ease-in-out;
      }
      
      
      @media screen and (max-width: 700px) {
        transform: scale(1.2);
      }
    }
    &__close-button {
        position: absolute;
        top: 140px;
        right: 250px;
        width: 2.5rem;
        height: 2.5rem;
        max-height: 2.5rem;
        min-width: 2.5rem;
        border-radius: 100%;
        margin-left: 0.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: var(--accent-color);
        @media screen and (max-width: 700px) {
          top: 210px;
          right: 20px;
        }

    }
    &__item {
        width: 1.1rem;
        max-height: 1.1rem;
        display: block;
        line-height: 0;
        fill: var(--text);
    }
}

@keyframes pulse {
  from {
    transform: scale(0);
    opacity: 0.1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes closing {
  from {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(.5);
    opacity: 0.7;
  }
  to {
    transform: scale(0);
    opacity: 0;
    display: none;
  }
}

@keyframes moveImg {
  0% {
    transform: translateX(0);
  }
  2% {
    transform: translateX(3px);
  }
  4% {
    transform: translateX(-3px);
  }
  6% {
    transform: translateX(3px);
  }
  8% {
    transform: translateX(-3px);
  }
  10% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}