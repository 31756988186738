.coef {
  font-weight: 900;
  font-size: 1rem;
  color: var(--text);
  background: var(--accent-color);
  padding: 0.3rem;
  position: absolute;
  white-space: nowrap;
  top: 0rem;
  right: 0rem;
  transition: font-size .5s;
  border-radius: 7px;
  z-index: 2;
  &_isOnly {
    bottom: calc(50% - 1rem);
    font-size: 2rem;
    top: initial;
    right: initial;
  }
  &_isStop {
    outline: rgba(0, 0, 0, 0.5) solid 20rem;
  }
}

@media screen and (min-width: 700px) {
  .coef {
    right: initial;
    top: .5rem;
    &_isOnly {
      top: initial;
    }
  }
}