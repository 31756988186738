.jackpot {
  width: 100%;
  &__item {
    margin-bottom: .8rem;
    height: 2.2rem;
    width: 100%;
  }
}

@media screen and (min-width: 700px) {
  .jackpot {
    &__item {
      margin-bottom: 1rem;
    }
  }
}