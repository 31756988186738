.promo-list-item {
    display: flex;
    width: 100%;
    column-gap: 15px;
    &__icon-container{
        width: 20%;
    }
    &__icon {
        width: 100%;
        height: 100%;
    }
    &__content {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        row-gap: 5px;
    }
    &__title {
        width: 100%;
    }
    &__description {
        width: 100%;
    }
}