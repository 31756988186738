.my-bets {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    padding: 0.2rem 0.5rem 1rem 0.5rem;
  }
  &__items {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  &__item {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 1rem 0.5rem;
    background: var(--component-background);
    border-radius: 10px;
    overflow: hidden;
    &:not(:last-child) {
      margin-bottom: 0.3rem;
    }
    &:first-child {
      animation: increaceResult 0.5s linear forwards;
    }
  }
  &__column {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media screen and (min-width: 700px) {
  .my-bets {
    &__items {
      max-height: initial;
    }
    &__header {
      &_lang {
        &_it {
          font-size: .75rem;
        }
      }
    }
  }
}

@keyframes increaceResult {
  0% {
    max-height: 0;
    padding: 0 0.5rem;
  }
  100% {
    padding: 1rem 0.5rem;
    max-height: 4rem;
  }
}