.pause {
  position: absolute;
  display: flex;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: uppercase;
  &__wick {
    height: .5rem;
    margin-top: 2rem;
    min-width: 75%;
    animation-name: wickAnimation;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 12.5%;
    margin-left: auto;
  }
  &__sparks {
    position: absolute;
  }
}

@media screen and (min-width: 700px) {
  .pause {
    font-size: 1.8rem;
  }
}

@keyframes wickAnimation {
  0% {
    min-width: 75%;
  }
  100% {
    min-width: 0%;
  }
}