.button {
  color: var(--text);
  font-weight: 500;
  border: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  border-radius: 7px;
  outline: none;
  white-space: initial;

  &_isVertical {
    text-orientation: upright;
    writing-mode: vertical-rl;
  }

  &_isBlock {
    outline: rgba(0, 0, 0, 0.4) solid 20rem;
  }

  &_isDisabled {
    opacity: .5;
    cursor: not-allowed !important;
  }

  &_isUppercase {
    text-transform: uppercase;
  }
  &_isBold {
    font-weight: bold;
  }
  &_fontSize {
    &_small {
      font-size: 0.8rem;
      line-height: .8rem;
    }
    &_normal {
      font-size: 1rem;
    }
    &_large {
      font-size: 1.2rem;
    }
    &_extra {
      font-size: 2.2rem;
    }
  }
  &_color {
    &_orange {
      background: var(--accent-color);
      border: 1px solid var(--accent-color);
    }
    &_orange-promo {
      background: var(--accent-color);
      border: unset;
    }
    &_dark {
      background: var(--main-background);
      border: 1px solid var(--accent-color);
    }
    &_dark-empty-promo {
      background: transparent;
      border: unset;
    }
    &_red {
      background: #ad2f2f;
      border: 1px solid var(--main-background);
    }
  }
  &_size {
    &_container {
      width: 100%;
      height: 100%;
    }
  }
  &__layout {
    &_size {
      &_container {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .button {
    cursor: pointer;
    &_fontSize {
      &_small {
        font-size: .9rem;
      }
      &_normal {
        font-size: 1rem;
        line-height: 1rem;
      }
      &_large {
        font-size: 1.25rem;
      }
    }
  }
}