.jackpot-window {
  width: 98vw;
  height: 98vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  padding: 11% 20% 34%;
  background: url('./img/bg-jackpot.png');
  background-size: 100% 100%;
  animation: startJackpotWindow .4s ease-in-out forwards;

  &__title {
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: bold;
    font-size: 1.7rem;
    background: -webkit-linear-gradient(var(--accent-color), #A3600F);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -.5px;
  }

  &__text-block {
    width: 30vw;
    text-align: center;
    text-transform: uppercase;
    color: #704009;
    font-weight: bold;
    font-size: .8rem;
    margin-top: 14vw;
  }

  &__text {
    white-space: nowrap;
  }

  &__amount {
    height: 2rem;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    z-index: 1;
    font-weight: 900;
  }
  &__currency {
    font-style: italic;
    margin-left: .2rem;
    color: #AC2F2F;
  }

  .odometer {
    color: #AC2F2F;
    font-family: Roboto;
    font-style: italic;
    letter-spacing: 0.2em;
  }
}

@media screen and (min-width: 700px) {
  .jackpot-window {
    width: 45vw;
    height: 45vw;
    padding: 11% 20% 35%;
    &__title {
      font-size: 2rem;
    }
    &__text-block {
      width: 15vw;
      margin-top: 7vw;
      font-size: 1.2rem;
    }
    &__amount {
      font-size: 3vw;
    }
  }
}

@keyframes startJackpotWindow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}