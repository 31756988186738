.promo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 10000;
    top: 0px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color:  rgba(19, 23, 29, 0.74);
    background-size: 100%;
    left: 0;
    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
    }
    &__text {
        display: flex;
        justify-content: center;
        width: 60%;
        overflow: auto;
        padding-bottom: 5px;

    }
    &__close-button-container {
        position: absolute;
        top: -1.25rem;
        right: -1.25rem;
        width: 2.5rem;
        height: 2.5rem;
        max-height: 2.5rem;
        min-width: 2.5rem;
        border-radius: 100%;
        margin-left: 0.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: var(--accent-color);
        z-index: 1000000;
    }
    &__close-button {
        width: 1.1rem;
        max-height: 1.1rem;
        display: block;
        line-height: 0;
        fill: var(--text);
    }
    &__content {
        position: relative;
        width: 80%;
        max-width: 80%;
        max-height: 80%;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        row-gap: 5px;
        box-shadow: 2px 2px 10px  var(--accent-color);
        z-index: 1000000;
        background-image: url('../img/back.png');
        background-repeat: repeat;
        background-color: var(--text);
        color: black;
        background-size: 200px;
        font-weight: bold;
    }
    &__img-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
    }
}