.game {
  width: 100%;
  color: var(--text);
  position: relative;
  &__header-layout {
    width: 100%;
    height: 4.2rem;
    margin-bottom: 0.7rem;
    &_isOpen {
      .header {
        border-radius: 10px 10px 0 0;
      }
    }
  }
  &__time-bar {
    width: 100%;
    height: 1.8rem;
  }
  &__bets-block-layout {
    width: 100%;
  }
  &__center-block {
    width: 100%;
    justify-content: space-between;
    margin-top: 0.8rem;
    box-sizing: border-box;
    padding: 10px;
    background: var(--main-background);
    border-radius: 10px;
  }
  &__last-card {
    width: 53%;
    padding: 1.4rem;
    box-sizing: border-box;
    background: var(--main-background);
    border-radius: 10px;
  }
  &__last-card-layout {
    width: 100%;
  }
  &__relatively-layout {
    width: 45%;
  }
  &__card-history-layout {
    width: 100%;
    margin-top: 0.5rem;
  }
  &__basket-layout {
    margin-top: .5rem;
    width: 100%;
  }
  &__chat-layout {
    width: 100%;
    margin-top: 0.5rem;
  }
  &__statistic-layout {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  &__jackpot-layout {
    width: 100%;
    margin-bottom: .5rem;
  }
}

@media screen and (min-width: 700px) {
  .game_platform_desktop {
    height: 100%;
    width: 100%;
    padding-top: 0;
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(11, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: .5rem;
    .game {
      &__left-header-layout {
        grid-column: 1 / 1;
        grid-row: 1 / 1;
      }
      &__statistic-layout {
        grid-column: 2 / 4;
        grid-row: 1 / 1;
        position: relative;
        margin-bottom: 0;
      }
      &__right-header-layout {
        grid-column: 4 / 4;
        grid-row: 1 / 1;
        position: relative;
      }
      &__history-layout {
        grid-column: 1 / 1;
        grid-row: 2 / 12;
      }
      &__game-layout {
        grid-column: 2 / 4;
        grid-row: 2 / 8;
        background: var(--main-background);
        border-radius: 10px;
        padding: 1rem;
        box-sizing: border-box;
      }
      &__basket-layout {
        grid-column: 2 / 4;
        grid-row: 8 / 12;
        margin-top: 0;
      }
      &__chat-layout {
        grid-column: 4 / 4;
        grid-row: 2 / 12;
        margin-top: 0;
        box-sizing: border-box;
        padding-top: 0;
        &_isOpen {
          padding-top: 16rem;
        }
      }
      &__jackpot-layout {
        grid-column: 4 / 4;
        grid-row: 2 / 4;
        margin-top: 0;
        box-sizing: border-box;
        padding-top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}