.notify-item {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 3rem;
  position: relative;
  z-index: 1000000;
  margin-top: 0.5rem;
  padding: 0.3rem 1rem;
  box-sizing: border-box;
  font-size: 1rem;
  color: var(--text);
  animation: 0.7s ease init;
  background: var(--component-background);
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  user-select: none;

  &_type {
    &_error {
      border-left: 5px solid #AC2F2F;
    }
  
    &_success {
      border-left: 5px solid var(--accent-color);
    }

    &_win {
      background-size: cover;
      border-left: 5px solid var(--accent-color);
      background: var(--component-background) url('../img/confeti.gif');
      background-size: cover;
    }
  }

  &_deleting {
    animation: 0.7s ease delete;
  }

  &__img {
    width: 2rem;
    line-height: 0;
  }

  &__close {
    min-width: 1.5rem;
    cursor: pointer;
  }

  &__text {
    flex-grow: 1;
    box-sizing: border-box;
    padding-left: 1rem;
    height: 100%;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    word-break: break-word;
  }

  @keyframes init {
    0% {
      opacity: 0;
      transform: translateX(10rem);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes delete {
    0% {
      opacity: 1;
      max-height: 3rem;
      min-height: 3rem;
      overflow: hidden;
      margin-top: 0.5rem;
    }
    70% {
      opacity: 0.5;
      transform: translateX(120%);
      margin-top: 0rem;
      padding: 0;
    }
    100% {
      opacity: 0;
      transform: translateX(200%);
      max-height: 0;
      min-height: 0;
      margin-top: 0rem;
      padding: 0;
      overflow: hidden;
    }
  }
}