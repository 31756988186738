.promo-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 5px;
    row-gap: 10px;

    &__no-promo {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

@media screen and (max-width: 700px) {
    .promo-list {
        &__no-promo {
            font-size: 1.5rem;
        }
    }
}