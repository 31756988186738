.history {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: calc(100% - 4.2rem);
  width: 100%;
  border-radius: 0 0 10px 10px;
  background: var(--main-background);
  z-index: 1003;
  top: 4.2rem;
  overflow: hidden;
  box-sizing: border-box;
  animation: increaceHistory 0.3s linear forwards;
  font-size: .9rem;
  // white-space: nowrap;

  &_isClosingHistory {
    animation: reduceHistory 0.3s linear forwards;
  }
  &__buttons-container {
    width: 100%;
    min-height: 2.8rem;
    display: flex;
    justify-content: space-between;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }
  &__button-container {
    width: 30%;
    height: 2.8rem;
  }
  &__promo-button {
    position: relative;
    height: 100%;
    width: 100%;
    background: transparent;
    border: 1px solid var(--component-background);
    border-radius: 7px;
    display: flex;
    align-items: center;
    &_isActive {
      background: var(--accent-color);
      border: 1px solid var(--accent-color);
    }
  }
  &__promo-count {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: var(--accent-color);
    aspect-ratio: 1/1;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 7px;
    font-size: 0.8rem;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;
    &_isSmallFont {
      font-size: 0.6rem;
    }
  }
  &__content-container {
    margin-top: 0.9rem;
    width: 100%;
    height: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
      margin-top: 3px;
      width: 2px;
      height: 0;
      opacity: 0;
    }
    &::-webkit-scrollbar-track {
      border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--accent-color);
      border-radius: 50px;
    }
  }
}

@media screen and (min-width: 700px) {
  .history {
    position: inherit;
    height: 100%;
    animation: none;
    padding: 0.5rem;
    border-radius: 10px;
    &__promo-count {
      padding: 0.5rem;
    }
  }
}

@keyframes increaceHistory {
  0% {
    max-height: 0;
    padding: 0 1rem;
  }
  100% {
    padding: 1rem;
    max-height: calc(100% - 4.2rem);
  }
}

@keyframes reduceHistory {
  0% {
    padding: 1rem;
    max-height: calc(100% - 4.2rem);
  }
  100% {
    max-height: 0;
    padding: 0 1rem;
  }
}