.messages {
  background: var(--settings-background);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 0rem .7rem;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    margin-top: 3px;
    width: 2px;
    height: 0;
    opacity: 0;
  }
  &::-webkit-scrollbar-track {
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--accent-color);
    border-radius: 50px;
  }
  overflow-y: scroll;
  &__item {
    width: 100%;
    margin-top: .7rem;
    margin-bottom: .7rem;
    background: var(--component-background);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: .7rem 1rem;
    border-radius: 10px;
    &_isAlien {
      flex-direction: row-reverse;
    }
  }

  &__item-text{
    width: 75%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &_isAlien {
      align-items: flex-start;
      margin-left: initial;
      margin-right: auto;
    }
  }

  &__icon {
    height: 2.5rem;
    width: 2.5rem;
    line-height: 0;
  }

  &__nick {
    color: #6379B0;
  }

  &__text {
    margin-top: .4rem;
    text-align: right;
    font-weight: 500;
    word-break: break-all;
    &_isAlien {
      text-align: left;
    }
  }
}