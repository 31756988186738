.input {
  width: 100%;
  min-height: 2.5em;
  text-align: left;
  box-sizing: border-box;
  outline: 0;
  padding: 0.5rem;
  font-size: 1rem;
  background: none;
  color: var(--text);
  border: none;
  border: 1px solid var(--text);
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 1;

  &__label {
    position: absolute;
    color: var(--text);
    z-index: 0;
  }

  &__label-text {
    margin-left: 0.2rem;
  }

  &__transparent-text {
    color: transparent;
  }

  &_type {
    &_textarea {
      height: 100%;
      border: none;
      background: var(--settings-background);
      border-radius: 10px;
      vertical-align: top;
      resize: none;
      &::placeholder {
        color: var(--chat-text);
      }
    }
  }

  &_unBorder {
    border: none;
  }

  &_centerText {
    text-align: center;
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(1)
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
