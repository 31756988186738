.chat {
  background: var(--main-background);
  border-radius: 10px;
  width: 100%;
  padding: 1rem .8rem;
  box-sizing: border-box;
  &__top-block {
    display: flex;
    width: 100%;
    align-items: center;
    font-weight: 500;
    font-size: 1.1rem;
  }
  &__ellipse {
    width: .6rem;
    margin-right: .5rem;
  }
  &__switcher {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  &__arrow {
    margin-left: 0.3rem;
    width: 1.2rem;
    transition: all .5s ease-out;
    &_isOpen {
      transform: rotate(180deg);
    }
  }
  &__bottom-block {
    display: flex;
    width: 100%;
    align-items: center;
    font-weight: 500;
    font-size: 1.1rem;
    color: var(--chat-text);
    margin-top: .8rem;
    padding: 0 .6rem;
    box-sizing: border-box;
  }
  &__input-container {
    height: 4rem;
    margin-top: 1rem;
  }
  &__send {
    width: 1.8rem;
    margin-left: auto;
    line-height: 0;
    cursor: pointer;
    &_unactive {
      cursor: not-allowed;
      * {
        fill: var(--component-background);
      }
    }
  }

  &__messages {
    margin-top: 0rem;
    width: 100%;
    max-height: 0;
    height: 0;
    transition: all 1s linear;
    overflow: hidden;
    &_isOpen {
      margin-top: 1rem;
      height: 15rem;
      max-height: 15rem;
    }
  }
}

@media screen and (min-width: 700px) {
  .chat {
    height: 100%;
    display: flex;
    flex-direction: column;
    &__messages {
      height: initial;
      max-height: initial;
      flex-grow: 1;
      margin-top: 1rem;
    }
    &__switcher {
      display: none;
    }
  }
}