// @import url(//db.onlinewebfonts.com/c/348d08041a921ea4a19a848c4f3c5dde?family=SnowCapCyr);
// @import url(//db.onlinewebfonts.com/c/0792a9629f51ed5c850d9a97a49b8785?family=Kabarett+Simple);

.new-year {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    z-index: 10000;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    @media screen and (max-width: 700px) {
        top: 0;
        font-size: 12px;
    }

    &__main-container {
        width: 100%;
        height: 100%; 
        display: flex; 
        flex-direction: column;
        overflow: auto;
    }

    &__img-container {
        position: relative;
        min-width: 370px;
    }

    &__frame-img {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__title {
        display: flex;
        justify-content: center;

        @media screen and (max-width: 700px) {
            margin-top: 20%;
        }
    }
    
    &__title-text {
        font-style: normal;
        font-weight: 700;
        font-size: 4em;
        color: #FF9615;
        font-family: 'SnowCapCyr';
    }

    &__text-container {
        width: 75%;
        flex-direction: column;
        display: flex;
        padding-left: 10%;
        @media screen and (max-width: 700px) {
            width: 80%;
            padding-left: 2%;
        }
    }

    &__text {
        font-family: 'Kabarett Simple';
        font-style: normal;
        font-weight: 100;
        font-size: 1.2em;
        line-height: 1.2em;
        @media screen and (max-width: 700px) {
            font-size: 1em;
        }
    }

    &__statistics {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 75%;
        padding-left: 10%;
        @media screen and (max-width: 700px) {
            width: 80%;
            padding-left: 2%;
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
        margin-top: auto;
        width: 100%;
        padding-left: 10%;
    }

    &__text-footer {
        color: black;
        font-size: 1.5em;
        line-height: 27px;
        font-family: 'Kabarett Simple';
    }

    &__close-button {
        position: absolute;
        right: 0;
        top: 0;
        max-height: 2.5em;
        min-width: 2.5em;
        max-width: 2.5em;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border-radius: 1.25em;
        background: #FF8D00;
        padding: 0.65em;
        box-sizing: border-box;
        transition: max-width 1s, max-height 1s;
        z-index: 1004;
    }

    &__close-icon {
        margin-left: auto;
        cursor: pointer;
        width: 1.2em;
        max-height: 1.2em;
        line-height: 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        > svg {
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1em 0;
        row-gap: 1em;
        box-sizing: border-box;
    }
}