.header {
  background: var(--main-background);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  box-sizing: border-box;
  color: var(--text);
  font-size: 0.7rem;
  white-space: nowrap;

  &__user-block {
    display: flex;
    align-items: center;
    height: 75%;
    margin-right: auto;
  }

  &__user-icon {
    height: 2.5rem;
    width: 2.5rem;
    position: relative;
  }

  &__user-svg {
    height: 100%;
    width: 100%;
    line-height: 0;
  }

  &__pencil {
    position: absolute;
    bottom: -5px;
    right: -5px;
    width: 1.4rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main-background);
    border: 1px solid var(--accent-color);
  }

  &__pencil-icon {
    width: 0.5rem;
    aspect-ratio: 1/1;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  
  &__user-info {
    margin-left: 0.5rem;
    display: flex;
    flex-direction: column;
  }

  &__user-id {
    font-size: 0.7rem;
    line-height: 0.8rem;
    color: var(--id-color);
  }

  &__user-nick {
    font-weight: bold;
    color: var(--text);
    line-height: 1rem;
    font-size: 0.9rem;
  }

  &__item {
    width: 2.5rem;
    height: 2.5rem;
    max-height: 2.5rem;
    min-width: 2.5rem;
    border-radius: 100%;
    margin-right: 0.6rem;
    background: var(--settings-background);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    svg {
      width: 100%;
      height: 100%;
    }
    &_isNewYear {
      background: none;
    }
  }
  &__icon {
    width: 1.1rem;
    max-height: 1.1rem;
    display: block;
    line-height: 0;
    &-svg {
      max-height: 1.1rem;
      * {
        fill: var(--text);
      }
    }
  }
  &__balance {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: var(--accent-color);
    line-height: 1rem;
    font-size: 0.9rem;
  }
  &__different-balance {
    animation: opacityBalance 2s linear forwards;
    font-weight: bold;
    display: block;
    margin-left: 1rem;
    &_isReduce {
      color: var(--component-background);
    }
    &_isIncreace {
      color: var(--accent-color);
    }
  }
  &__player {
    display: flex;
    font-size: 0.7rem;
    flex-grow: 2;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  &__burger {
    position: relative;
    display: initial;
    width: 1.8rem;
    cursor: pointer;
    &_close {
      width: 1.5rem;
      margin: auto .15rem;
    }
  }

  &__burger-icon {
    width: 100%;
    height: 100%;
    color: var(--text);
    &_promo {
      color: var(--accent-color);
    }
  }

  &__promo-count {
    position: absolute;
    z-index: 1000;
    top: -0.6rem;
    right: -0.3rem;
    width: 1rem;
    aspect-ratio: 1/1;
    background-color: var(--accent-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.6rem;
  }
  &__round-info {
    margin-left: 0.8rem;
    font-size: 0.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 700px) {
  .header {
    background: none;
    overflow: initial;
    max-height: 4rem;
    padding: 0;
    &__item {
      display: none;
    }
    &__burger {
      display: none;
    }
    &__user-block {
      height: 100%;
      margin-right: initial;
      flex-grow: 1;
    }
    &__user-icon {
      height: 3.4rem;
      width: 3.4rem;
      border-radius: 100%;
      // border: 4px solid var(--main-background);
      box-shadow:  0 0 0 4px var(--main-background);
    }
    &__user-info {
      margin-left: 1.5rem;
      flex-grow: 1;
    }
    &__user-id {
      font-size: 1rem;
      line-height: 1rem;
      margin-left: .5rem;
    }
    &__user-nick {
      line-height: 1.1rem;
      font-size: 1.1rem;
      margin-left: .5rem;
    }
    &__balance {
      line-height: 1rem;
      font-size: 1rem;
      flex-grow: 1;
      background: var(--main-background);
      border-radius: 8px;
      box-sizing: border-box;
      padding: .4rem .6rem;
    }
    &__different-balance {
      animation: opacityBalance 2s linear forwards;
      font-weight: bold;
      display: block;
      margin-left: 1rem;
      &_isReduce {
        color: var(--component-background);
      }
      &_isIncreace {
        color: var(--accent-color);
      }
    }
  }
}

@keyframes opacityBalance {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
    transform: scale(1)
  }
  50% {
    transform: scale(1.6)
  }
  85% {
    opacity: 1;
    transform: scale(1)
  }
  100% {
    opacity: 0;
  }
}